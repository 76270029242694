import React, { useCallback, useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { PAYPAL_CLIENT_ID } from '../../app/config';
import { Box, Stack, Typography, Button } from '@mui/material';
import PayPayTokerOrderButton from './PayPalTokenOrderButton';
import Spacer from '../common/Spacer';

const PayPalTokenOrder = ({ order, onCancel, onSuccess, onError }) =>
{
    return (
        <PayPalScriptProvider options={
            {
                "client-id": PAYPAL_CLIENT_ID,
                "currency": "EUR"
            }}>
            <Box sx={{ p: 0 }}>
                <Typography variant='h1'>Confirm your order</Typography>
                <Spacer height={10} />
                <Typography variant='h3'>Amount: <b>{order.tokens} Tokens</b></Typography>
                <Typography variant='h3'>Total: <b>{order.price} EUR</b></Typography>
                <Spacer height={20} />
                <Typography variant='h6'>Pay now with your trusted payment provider:</Typography>
                <Box sx={{backgroundColor:'#FFF', p:2, borderRadius:'15px'}}>
                    <PayPayTokerOrderButton order={order} onSuccess={onSuccess} onError={onError} />
                </Box>
                <Spacer height={20} />
                <Button variant='outline_premium' onClick={onCancel}>Choose another package</Button>
            </Box>
        </PayPalScriptProvider>
    );
};

export default PayPalTokenOrder;
