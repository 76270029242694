import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import InsightsRoundedIcon from '@mui/icons-material/InsightsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Button, Stack, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { theme } from '../../app/styles';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PlaylistPlayRoundedIcon from '@mui/icons-material/PlaylistPlayRounded';
import AnswerCheckBox from '../onboarding/AnswerCheckBox';
import ActionCheckBox from './ActionCheckBox';
import { fs_updateProperty } from '../../app/firestore';
import { useDispatch } from 'react-redux';
import { setSessionProperty } from '../user/UserSlice';
import Lottie from 'react-lottie';
import completeAnimation from '../../animations/complete_animation.json';
import { useRef } from 'react';

export default function SessionsTimelineItem(props)
{
    const dispatch = useDispatch();
    const [isLottieVisible, set_isLottieVisible] = React.useState(false);

    const customIcons = {
        0: {
            icon: <span />,
            label: '',
        },
        1: {
            icon: <SentimentVeryDissatisfiedIcon color="error" sx={{ fontSize: 14, paddingTop: '1px' }} />,
            label: 'Useless',
        },
        2: {
            icon: <SentimentDissatisfiedIcon color="error" sx={{ fontSize: 14, paddingTop: '1px' }} />,
            label: 'Poor',
        },
        3: {
            icon: <SentimentSatisfiedIcon color="warning" sx={{ fontSize: 14, paddingTop: '1px' }} />,
            label: 'Okay',
        },
        4: {
            icon: <SentimentSatisfiedAltIcon color="success" sx={{ fontSize: 14, paddingTop: '1px' }} />,
            label: 'Good',
        },
        5: {
            icon: <SentimentVerySatisfiedIcon color="success" sx={{ fontSize: 14, paddingTop: '1px' }} />,
            label: 'Excellent',
        },
    };

    const getIcon = name =>
    {
        switch (name)
        {
            case "CheckOutlinedIcon": return <CheckOutlinedIcon sx={{ color: theme.palette[props.color].main }} onClick={props.onClick} />;
            case "InsightsRoundedIcon": return <InsightsRoundedIcon sx={{ color: theme.palette[props.color].main }} onClick={props.onClick} />;
            case "LockOutlinedIcon": return <LockOutlinedIcon sx={{ color: theme.palette[props.color].main }} onClick={props.onClick} />;
            case "PlayArrowOutlinedIcon": return <PlayArrowOutlinedIcon sx={{ color: theme.palette[props.color].main }} onClick={props.onClick} />;
            case "AddOutlinedIcon": return <AddOutlinedIcon sx={{ color: theme.palette[props.color].main }} onClick={props.onClick} />;
            case "ClearOutlinedIcon": return <QuestionMarkRoundedIcon sx={{ color: theme.palette[props.color].main }} onClick={props.onClick} />;
            case "DeleteForeverOutlinedIcon": return <DeleteForeverOutlinedIcon sx={{ color: theme.palette[props.color].main }} onClick={props.onClick} />;
            case "CloudDownloadOutlinedIcon": return <CloudDownloadOutlinedIcon sx={{ color: theme.palette[props.color].main }} onClick={props.onClick} />;
            case "PlaylistPlayRoundedIcon": return <PlaylistPlayRoundedIcon sx={{ color: theme.palette[props.color].main }} onClick={props.onClick} />;
            default: return <FiberManualRecordOutlinedIcon sx={{ color: theme.palette[props.color].main }} onClick={props.onClick} />
        }
    }

    const getStars = () =>
    {
        let output = '';
        for (var i = 0; i < props.stars; i++)
        {
            output += '⚝ ';
        }

        return output;
    }

    const getRating = () =>
    {
        if (props.rating === undefined || props.rating === null)
            return <span />;

        return customIcons[props.rating].icon;
    }

    const renderSubtitle = () =>
    {
        //if (props.rating !== undefined || props.stars > 0)
        if (props.stars > 0)
            return (
                <Stack direction='row' spacing={1}>
                    {/*getRating()*/}
                    <Typography
                        variant="timeline_stars"
                        component="span">
                        {getStars()}
                    </Typography>
                </Stack>);
    }

    const onActionsChange = (sessionId, index, isChecked) =>
    {
        let oldActions = structuredClone(props.actions);
        //if actions are an array of strings, an old format.. then convert it to an array of objects!
        if (oldActions[0].isChecked === undefined)
        {
            let tempActions = [];
            for (var i = 0; i < oldActions.length; i++)
            {
                if (i === index)
                    tempActions.push({ title: oldActions[i], isChecked: true });
                else
                    tempActions.push({ title: oldActions[i], isChecked: false });
            }
            oldActions = tempActions;
        }

        oldActions[index].isChecked = isChecked;

        //check if all actions are complete and play animation
        let actionsComplete = true;
        for (var i = 0; i < oldActions.length; i++)
        {
            if (!oldActions[i].isChecked)
                actionsComplete = false;
        }

        if (actionsComplete)
            set_isLottieVisible(true);
            //lottieRef.current?.play(); // this doesn't work

        if (!actionsComplete)
            set_isLottieVisible(false);

        //update session in firestore
        fs_updateProperty(sessionId, 'actions', oldActions, () => { props.onActionsChange() });
        
    }

    const renderActions = () =>
    {
        if (props.actions !== undefined && props.actions.length > 0)
        {
            let oldActions = props.actions;
            //if actions are an array of strings, an old format.. then convert it to an array of objects!
            if (oldActions[0].isChecked === undefined)
            {
                let tempActions = [];
                for (var i = 0; i < oldActions.length; i++)
                {
                    tempActions.push({ title: oldActions[i], isChecked: false });
                }
                oldActions = tempActions;
            }

            let newActions = [];
            for (var i = 0; i < oldActions.length; i++)
            {
                newActions.push(<ActionCheckBox
                    key={i + 'actioncheck'}
                    size='small'
                    label={oldActions[i].title}
                    sessionId={props.sessionId}
                    index={i}
                    isChecked={oldActions[i].isChecked}
                    onClick={onActionsChange} />);
            }
            
            return newActions;
        }
        else
            return null;
    }

    const lottieRef = useRef(null);

    
    return (
        <TimelineItem>
            {isLottieVisible && <Box sx={{
                position: 'absolute',
                left: '-42px',
                top: '53%',
                transform: 'translateY(-52%)'
            }}>
                <Lottie
                    isClickToPauseDisabled={true}
                    lottieRef={lottieRef}
                    options={{
                        loop: false,
                        autoplay: false,
                        animationData: completeAnimation,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={120}
                    width={120}
                />
            </Box>}
            <TimelineSeparator>
                <TimelineConnector sx={{ bgcolor: theme.palette[props.color].main }} />
                <TimelineDot color={props.color} variant="outlined">
                    {getIcon(props.icon)}
                </TimelineDot>
                <TimelineConnector sx={{ bgcolor: theme.palette[props.color].main }} />
            </TimelineSeparator>
            <TimelineContent sx={{ m: 'auto 0', lineHeight: '1' }}>
                <Stack spacing={0}>
                    <Button variant={props.buttonVariant} disabled={props.disabled === 'abandoned' ? true : false} onClick={props.onClick}>
                    <Stack>
                        <Typography variant="timeline_date" component="span" sx={{ color: props.textColor !== undefined ? props.textColor : theme.palette[props.color].main, padding: '0px 0px 3px 0px' }}>
                            {props.when}
                        </Typography>
                        <Typography variant="timeline_title" component="span" sx={{ display: 'flex', color: props.textColor !== undefined ? theme.palette[props.textColor].main : theme.palette[props.color].main, padding: '0px' }}>
                            {props.title}
                        </Typography>
                        
                    </Stack>
                </Button>
                {renderActions()}
                </Stack>
            </TimelineContent>
        </TimelineItem>
    );
}
