import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import Spacer from '../common/Spacer';

const PieCanvas = ({ radius, percent, borderColor, countdownColor, label, borderSize = 1, onClick = null}) =>
{
    useEffect(() =>
    {
        
    }, []);

    let size = radius * 2;
    let smallerSize = size * 0.83;
    
    return <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        sx={{ position: 'relative' }}
        onClick={e => { if (onClick !== null) onClick() }}
    >
        <Box position='relative' display="flex" justifyContent="center" alignItems="center">
            <CircularProgress variant="determinate" value={100} thickness={borderSize} size={size} sx={{ color: borderColor }} />
            <CircularProgress variant="determinate" value={percent * 100} thickness={22} size={smallerSize} sx={{
            position: 'absolute',
            color: countdownColor,
            }} />
        </Box>
        <Spacer height={10} />
        <Typography sx={{ color: borderColor, maxWidth: radius * 3, lineHeight:1, textAlign: 'center' }} variant="caption">{label}</Typography>
    </Stack>;
};

export default PieCanvas;