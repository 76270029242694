import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import { ROLES } from '../../app/config';
import { getFreeTrialDaysLeft } from '../../app/roleUtils';
function ProgressTimer({ currentValue, maxValue, ...props })
{
    const user = useSelector(getUser);
    let newValue = Math.ceil((currentValue / maxValue) * 100);

    const formatSeconds = (totalSeconds) =>
    {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        // Pad minutes and seconds with leading zeros if less than 10
        const paddedMinutes = minutes.toString().padStart(2, '0');
        const paddedSeconds = seconds.toString().padStart(2, '0');
        return `${hours}:${paddedMinutes}:${paddedSeconds}`;
    };

    const freeDaysLeft = getFreeTrialDaysLeft();
    const labelValue = formatSeconds(maxValue - currentValue);
    let finalLabel = 'Your Free Trial is over!';

    if ((user.role === ROLES.GUEST && freeDaysLeft > 0) || user.role === ROLES.SUBSCRIBER || user.role === ROLES.ADMIN)
        finalLabel = currentValue === maxValue ? 'Daily Session Available!' : 'New Session Available in ' + labelValue;

    if (finalLabel === 'Your Free Trial is over!')
        newValue = 100;

    return (
        <Box sx={{ display: 'flex', flexGrow: 100, alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1, position: 'relative' }}>
                <LinearProgress variant="determinate" value={newValue} {...props} />
                <Typography variant="body2" color="white" sx={{ position: 'absolute', top: 6, width:'100%', textAlign:'center' }}>
                    {finalLabel}
                </Typography>
            </Box>
        </Box>
    );
}

ProgressTimer.propTypes = {
    currentValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
};

const DailySessionTimer = styled(ProgressTimer)(({ theme }) => ({
    height: 32,
    width: '100%',
    borderRadius: 20,
    border: '1px solid #ffffff15',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'transparent',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 0,
        backgroundColor: '#ffffff10',
    },
}));

export default DailySessionTimer;