import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Rating, Stack, Typography, Grid } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));

const customIcons = {
    0: {
        icon: <span />,
        label: '',
    },
    1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" sx={{ fontSize: 40 }} />,
        label: 'Useless',
    },
    2: {
        icon: <SentimentDissatisfiedIcon color="error" sx={{ fontSize: 40, paddingLeft: '15px' }} />,
        label: 'Poor',
    },
    3: {
        icon: <SentimentSatisfiedIcon color="warning" sx={{ fontSize: 40, paddingLeft: '15px' }} />,
        label: 'Okay',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon color="success" sx={{ fontSize: 40, paddingLeft: '15px' }} />,
        label: 'Good',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon color="success" sx={{ fontSize: 40, paddingLeft:'15px' }} />,
        label: 'Excellent',
    },
};

function IconContainer(props)
{
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};

export default function SessionRating(props)
{
    const [value, setValue] = React.useState(props.value === undefined ? 3 : props.value);
    const [hover, setHover] = React.useState(-1);

    return (

        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >

        <StyledRating
            name="highlight-selected-only"
            value={value}
            IconContainerComponent={IconContainer}
            getLabelText={(value) => customIcons[value].label}
            highlightSelectedOnly
            onChange={(event, newValue) =>
            {
                setValue(newValue);
                props.onChangeValue(newValue);
            }}
            onChangeActive={(event, newHover) =>
            {
                setHover(newHover);
            }}
            />
            {value !== null && (
                <Typography variant="h6">{customIcons[hover !== -1 ? hover : value].label}</Typography>
            )}
        </Grid>
    );
}
