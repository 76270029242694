import { Container, Grid, Typography } from '@mui/material';
import { default as React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fs_createSession, fs_getSessionMessages, fs_loadSession } from '../../app/firestore';
import Spacer from '../common/Spacer';
import { getPreprompt, getSession, getUser, setSession, setSessionMessages } from '../user/UserSlice';


function LoadAdminSession(props)
{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const session = useSelector(getSession);
    const preprompt = useSelector(getPreprompt);
    
    useEffect(() =>
    {
        onStart();
        
        // Clean up the timer if the component is unmounted or timer is restarted
        return () => {};
    }, []);

    const onStart = e =>
    {
        //If new session, create it
        if (session.id === 0)
            fs_createSession(onSessionCreated);
        else
            fs_loadSession(session.id, onSessionLoaded);
    }  

    const onSessionCreated = sessionId =>
    {
        dispatch(setSession({ id: sessionId }));

        let sessionMessages = getDefaultSessionMessages();
        dispatch(setSessionMessages(sessionMessages));

        navigate("/admin_session", { replace: true });
    }

    const onSessionMessagesLoaded = messages =>
    {
        let defaultSessionMessages = getDefaultSessionMessages();
        let sessionMessages = defaultSessionMessages.concat(messages);
        dispatch(setSessionMessages(sessionMessages));

        navigate("/admin_session", { replace: true });
    }

    const onSessionLoaded = session =>
    {
        dispatch(setSession(session));
        fs_getSessionMessages(session.id, onSessionMessagesLoaded);
    }

    const getDefaultSessionMessages = () =>
    {
        let sessionMessages = [];
        sessionMessages.push({
            role: "system",
            content: preprompt.preprompt_text
        });
        sessionMessages.push({
            role: "system",
            content: "Hello " + user.name.split(" ")[0] + ", what would you like to talk about?"
        });
        return sessionMessages;
    }

    return (
        <Container disableGutters maxWidth="sm">

            <Grid
                container
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
            >

                <Spacer height={50} />
                <Typography variant="h3">ADMIN Session is starting soon...</Typography>
                <Spacer height={50} />
                <img src="./load-session.png" alt="loading" width="200" height="200" />
                <Spacer height={50} />
                
            </Grid>
        </Container>
    );
}

export default LoadAdminSession;
