import React from 'react';
import { Typography } from '@mui/material';

const LoadingScreen = (props) =>
{
    return (
        <div className="loading-screen">
            <div className="loading-spinner">
                <svg viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r="40" />
                </svg>
            </div>
            <Typography variant="h4">Loading ...</Typography>
        </div>
    );
};

export default LoadingScreen;