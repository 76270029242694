import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Button, Stack } from '@mui/material';
import { useWavesurfer } from '@wavesurfer/react';
import React, { useEffect, useRef, useState } from "react";
import { APP_URL } from '../../app/config';

function VADOlivya(props)
{
    const [isAudioLoaded, setIsAudioLoaded] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [internalBlob, setInternalBlob] = useState(null);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    
    const containerRef = useRef(null);

    const { wavesurfer } = useWavesurfer(
        {
            container: containerRef,
            mediaType: 'audio/aac', // Specify the audio file type,
            //backend: 'WebAudio', // add this too
            height: 24,
            splitChannels: false,
            normalize: true,
            waveColor: "#6c318e",
            progressColor: "#C571F6",
            cursorColor: "#FFFFFF", 
            cursorWidth: 1,
            barWidth: 4,
            barGap: 3,
            barRadius: 30,
            barHeight: 4,
            barAlign: "",
            minPxPerSec: 1,
            fillParent: true,
            mediaControls: false,
            url: APP_URL + "silent.mp3",
            autoplay: true,
            interact: true,
            dragToSeek: false,
            hideScrollbar: true,
            audioRate: 1,
            autoScroll: true,
            autoCenter: true,
            sampleRate: 8000,
            
        }
    );

    useEffect(() =>
    {
        wavesurfer && wavesurfer.pause();

    }, [props.isRecording]);

    useEffect(() =>
    {
        if (props.audioBlob !== internalBlob)
        {
            setInternalBlob(props.audioBlob);
            setIsAudioLoaded(false);
            wavesurfer.setTime(0);
            wavesurfer.load(URL.createObjectURL(props.audioBlob))
            //wavesurfer.loadBlob(props.audioBlob);
        }

    }, [props.audioBlob]);
    
    useEffect(() =>
    {
        if (wavesurfer)
        {
            wavesurfer.on('play', () =>
            {
                setIsPlaying(true);
            })

            wavesurfer.on('pause', () =>
            {
                setIsPlaying(false);
            })

            wavesurfer.on('ready', () =>
            {
                setIsAudioLoaded(true);
            })

            props.onReady(wavesurfer);
        }

    }, [wavesurfer]);
    
    const onPlayPause = () =>
    {
        wavesurfer && wavesurfer.playPause();
    }
    
    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={0}
            
        >
            {isAudioLoaded && (<Button onClick={onPlayPause} variant="playpause_olivya">
                {!isPlaying ? <PlayArrowIcon /> : <PauseIcon />}
            </Button>)}
            {!isAudioLoaded && <div></div> }
            <Box
                id='olivya-audio-id'
                name='olivya-audio-name'
                key='olivya-audio-key'
                ref={containerRef}
                sx={{ width: '100%' }} />
        </Stack>
    );
    
}

export default VADOlivya;