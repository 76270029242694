import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { ROLES } from '../../app/config';

const initialState = {
    user: {
        name: 'there',
        role: ROLES.GUEST,
        onboarding: true,
    }
};

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        loginUser: (state, action) =>
        {
            state.user = action.payload;
            state.user.lastLogin = dayjs().format();
        },
        setUserProperty: (state, action) =>
        {
            state.user[action.payload.property] = action.payload.value;
        },
        setUserProperties: (state, action) =>
        {
            let newUser = { ...state.user, ...action.payload };
            state.user = newUser;
        },
        setSession: (state, action) =>
        {
            state.user.session = action.payload;
        },
        setLastSession: (state, action) =>
        {
            state.user.last_session = action.payload;
        },
        setSessions: (state, action) =>
        {
            state.user.sessions = action.payload;
        },
        setSessionMessages: (state, action) =>
        {
            if (state.user.session === undefined) state.user.session = {};
            state.user.session.messages = action.payload;
        },
        addSessionMessage: (state, action) =>
        {
            if (state.user.session === undefined) state.user.session = {};
            if (state.user.session.messages === undefined) state.user.session.messages = [];

            state.user.session.messages.push(action.payload);
        },
        setSessionProperty: (state, action) =>
        {
            if (state.user.session === undefined) state.user.session = {};
            state.user.session[action.payload.property] = action.payload.value;
        },
        setPreprompt: (state, action) =>
        {
            if (state.user.preprompt === undefined) state.user.preprompt = {};
            state.user.preprompt = action.payload;
        },
        setError: (state, action) =>
        {
            state.user.error = action.payload;
        },
        setWeeklyReport: (state, action) =>
        {
            state.user.currentWeeklyReport = action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) =>
    {
        
    },
});

export const getUser = (state) => state.user.user;
export const getSession = (state) => state.user.user.session;
export const getLastSession = (state) => state.user.user.last_session;
export const getSessions = (state) => state.user.user.sessions !== undefined ? state.user.user.sessions : [];
export const getSessionMessages = (state) => state.user.user.session.messages;
export const getPreprompt = (state) => state.user.user.preprompt;
export const getError = (state) => state.user.user.error;
export const getWeeklyReport = (state) => state.user.user.currentWeeklyReport;

export const {
    loginUser,
    setUserProperty,
    setUserProperties,
    setSession,
    setLastSession,
    setSessions,
    setSessionMessages,
    setSessionProperty,
    addSessionMessage,
    setPreprompt,
    setError,
    setWeeklyReport,
} = UserSlice.actions;

export default UserSlice.reducer;
