import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { AppBar, Avatar, Backdrop, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Menu, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material';
import { default as React, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fs_addSessionMessage, fs_deleteSession, fs_updateProperty } from '../../app/firestore';
import Spacer from '../common/Spacer';
import LoadingScreen from '../dialog/LoadingScreen';
import Header from '../session/Header';
import { addSessionMessage, getSession, getSessionMessages, getUser, setSession } from '../user/UserSlice';

function AdminSession(props)
{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const session = useSelector(getSession);
    const sessionMessages = useSelector(getSessionMessages);

    const bottomRef = useRef(null);

    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    
    const [isRecording, setRecording] = useState(false);
    const [didSessionProgress, setDidSessionProgress] = useState(false);

    const findLastMessage = role =>
    {
        for (var i = sessionMessages.length - 1; i > 0; i--)
        {
            if (sessionMessages[i].role === role)
                return sessionMessages[i].content;
        }
        return '...';
    }

    const [olivyaMessage, setOlivyaMessage] = useState(findLastMessage('system'));
    const [myMessage, setMyMessage] = useState(findLastMessage('user'));
    const [myChatMessage, setMyChatMessage] = useState('');

    const [openCompleteDialog, setOpenCompleteDialog] = React.useState(false);
    const [openAbandonDialog, setQuitDialog] = React.useState(false);
    

    useEffect(() =>
    {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        // cleanup function
        return () => {};
    }, [olivyaMessage]);

    const onOlivyaMessage = message =>
    {
        fs_addSessionMessage(session.id, message, () => { });
        dispatch(addSessionMessage(message));
        setOlivyaMessage(message.content);
    }

    const onMyMessage = message =>
    {
        //stupid! needs refactoring..
        //when recording is done, the UI switch to the last message, but I want it to be "..." until the transcript arrives
        if (message === '...')
        {
            setMyMessage(message);
            return;
        }

        fs_addSessionMessage(session.id, message, () => { });
        dispatch(addSessionMessage(message));
        setMyMessage(message.content);
        setDidSessionProgress(true);
    }
    
    const onSessionPause = () =>
    {
        navigate("/admin_sessions", { replace: true });
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) =>
    {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () =>
    {
        setAnchorEl(null);
        setOpenCompleteDialog(false);
        setQuitDialog(false);
    };

    const onMarkAsComplete = () =>
    {
        let newSession = { ...session };
        newSession.status = 'started';
        dispatch(setSession(newSession));
        setOpenCompleteDialog(false);
        navigate("/session_completed", { replace: true })
    }

    const onDeleteSession = () =>
    {
        fs_deleteSession(session.id, () => { navigate("/admin_sessions", { replace: true }) });
    }

    const onQuitSession = () =>
    {
        fs_updateProperty(session.id, 'status', 'abandoned', () => { onQuitSessionFinally() });
    }

    const onMarkAsStarted = () =>
    {
        fs_updateProperty(session.id, 'status', 'started', () => {});
    }

    const onQuitSessionFinally = () =>
    {
        fs_updateProperty(session.id, 'short_summary', 'You quit this session', () => { setQuitDialog(false); navigate("/", { replace: true }) });
    }

    const sendMyChatMessage = () =>
    {
        //sendMesssage(myChatMessage, sessionMessages, onReply);
        onMyMessage({
            role: 'user',
            content: myChatMessage
        });
        setMyChatMessage('');
    }

    const onReply = messageObj =>
    {
        onOlivyaMessage(messageObj);
    }

    return (        
        <Container disableGutters maxWidth="sm">
            <Backdrop open={openBackdrop} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <LoadingScreen />
            </Backdrop>
            <Header onBack={onSessionPause} timerTicks={0} onComplete={onMarkAsComplete} created={session.created} onTimerFinish={() => {}} />
            <Stack sx={{ width: '100%', alignItems: 'center' }}>
                <Stack sx={{ width: '85%' }} spacing={5}>
                        <Spacer height={20} />

                        {sessionMessages.map((message, index) =>
                        {
                            if (message.role === 'user') return (<Paper key={"msg_" + index} variant="myMessage" sx={{ position: 'relative' }}>
                                <Typography variant="body2">{message.content}</Typography>
                                <Avatar
                                    alt={user.name}
                                    src={user.photoURL}
                                    sx={{
                                        border: '2px solid #9BBEFF',
                                        position: 'absolute',
                                        bottom: -20,
                                        right: -20
                                    }}
                                />
                            </Paper>)
                            else
                                return (<Paper key={"msg_" + index} variant="olivyaMessage" sx={{ position: 'relative' }}>
                                    <Typography variant="body2">{message.content}</Typography>
                                    <Avatar
                                        alt="Olivya"
                                        src="https://talk.olivya.ai/logo192.png"
                                        sx={{
                                            border: '2px solid #C571F6',
                                            position: 'absolute',
                                            bottom: -20,
                                            left: -20
                                        }}
                                    />
                                </Paper>)
                        })}

                    
                    <Spacer height={240} />
                    <div ref={bottomRef} ></div>
                </Stack>
            </Stack>

            <Dialog
                open={openCompleteDialog}
                onClose={handleClose}
            >
                <DialogContent>
                        <Typography >Please confirm that you want to complete this session?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="lightestBlue" onClick={handleClose}>Back to the session</Button>
                    <Button variant='contained' color="lightGreen" onClick={onMarkAsComplete} autoFocus>
                        COMPLETE the session
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openAbandonDialog}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography><b>QUIT THE SESSION</b></Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>You still have some minutes left in this session.</Typography>
                    <br/>
                    <Typography>Please confirm if you want to quit or complete this session?</Typography>
                </DialogContent>
                <DialogActions>
                    <Stack spacing={2}>
                    <Button variant='contained' color="lightGreen" onClick={onMarkAsComplete} autoFocus>
                        COMPLETE
                    </Button>
                    <Button variant='contained' color="secondary" onClick={onQuitSession} autoFocus>
                        Quit
                        </Button>
                    <Button color="lightestBlue" onClick={handleClose}>Go Back</Button>
                    </Stack>
                </DialogActions>
            </Dialog>

            <AppBar position="fixed" sx={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', padding: 2, backgroundImage: 'none', top: 'auto', bottom: 0 }} color="lightBlue" enableColorOnDark>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >

                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            },
                        }}
                        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    >
                        <MenuItem onClick={onMarkAsStarted}>Mark as Started</MenuItem>
                        <MenuItem onClick={onMarkAsComplete}>Complete Session</MenuItem>
                        <MenuItem onClick={onDeleteSession}>Delete Session</MenuItem>
                    </Menu>
                    <TextField
                        id="preprompt-text"
                        label="Enter message"
                        multiline
                        rows={5}
                        variant="filled"
                        name="myChatMessage"
                        value={myChatMessage}
                        onChange={e => setMyChatMessage(e.currentTarget.value) }
                        sx={{width:'70%'} }
                    />
                    <Button variant="bright" onClick={sendMyChatMessage}><SendRoundedIcon /></Button>
                </Grid>
            </AppBar>
            </Container>
    );
}

export default AdminSession;
