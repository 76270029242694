import { showError } from './showError';
import { BACKEND_URL } from './config';
import * as Sentry from "@sentry/react";

export const pp_getSubscriptionStatus = async (subscriptionId, callback) =>
{
    try
    {
        const response = await fetch(BACKEND_URL + 'getSubscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ subscriptionId: subscriptionId })
        });

        const data = await response.json();
        callback(data.subscription);

    } catch (error)
    {
        Sentry.captureException(error);
        console.error('getSubscriptionStatus: ', error);
        showError('getSubscriptionStatus: ' + error.toString());
        callback('error');
    }
}
