import React from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, loginUser } from '../user/UserSlice';
import { fs_loginUser } from '../../app/firestore';
import LoadingScreen from '../dialog/LoadingScreen';

export const withAuthentication = (Component) =>
{
    return (props) =>
    {
        const [loading, setLoading] = React.useState(true);
        const [authenticated, setAuthenticated] = React.useState(false);
        const [authUser, setAuthUser] = React.useState({});
        const dispatch = useDispatch();
        const appUser = useSelector(getUser);
        

        React.useEffect(() =>
        {
            const auth = getAuth();
            const unsubscribe = onAuthStateChanged(auth, (user) =>
            {
                setAuthUser(user);
            });
            return () =>
            {
                unsubscribe(); // Cleanup the subscription on unmount
            }
        }, []);

        React.useEffect(() =>
        {
            if (authUser && authUser.uid !== undefined)
            {
                setAuthenticated(true);

                if (authUser.uid !== appUser.uid)
                    fs_loginUser(handleGetUserData);
                else
                    setLoading(false);
            }

            if (authUser === null)
                setLoading(false);
            
            return () =>
            {
                 // Cleanup the subscription on unmount
            }
        }, [authUser]);

        const handleGetUserData = (user) =>
        {
            dispatch(loginUser(user));
            setLoading(false);
        }

        if (loading)
        {
            return <LoadingScreen />; // You can replace this with a loading spinner or other placeholder
        }

        if (!authenticated)
        {
            return <Navigate  to="/login" />;
        }

        return <Component {...props} />;
    };
};
