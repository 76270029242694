import dayjs from 'dayjs';

export const getTimeOfDayCSSClass = () =>
{
    let timeOfDay = 'g19';
    const currentHour = dayjs().format('HH');
    if (currentHour >= 12 && currentHour < 19) timeOfDay = 'g4';
    if (currentHour >= 19 && currentHour < 24) timeOfDay = 'g21';
    if (currentHour >= 0 && currentHour < 4) timeOfDay = 'g21';

    return timeOfDay;
}

export const getTimeOfDayText = () =>
{
    let timeOfDay = 'morning';
    const currentHour = dayjs().format('HH');
    if (currentHour >= 12 && currentHour < 19) timeOfDay = 'afternoon';
    if (currentHour >= 19 && currentHour < 24) timeOfDay = 'evening';
    if (currentHour >= 0 && currentHour < 4) timeOfDay = 'evening';

    return timeOfDay;
}