import React, { useCallback, useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { PAYPAL_CLIENT_ID } from '../../app/config';
import { Box, Stack, Typography } from '@mui/material';
import LoadingScreen from '../dialog/LoadingScreen';

const PayPalTokenOrderButton = ({ order, onSuccess, onError }) =>
{
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [getOrder, setOrder] = useState({ tokens: 0, price: 0 });
    const [getLoading, setLoading] = useState(true);

    useEffect(() =>
    {
        setLoading(true);
        // Add artificial delay to re-render PayPal Buttons as they need a clean state when initialising
        setTimeout(() =>
        {
            updateOrder();
        }, 1000);
        
        
        // cleanup function
        return () => { };
    }, [order]);

    const updateOrder = () =>
    {
        if (order.price !== getOrder.price)
        {
            setOrder(true);
            setLoading(false);
        }
    }

    const onCreateOrder = (data, actions) =>
    {
        console.log("createOrder: " + order.price);
        return actions.order.create({
            purchase_units: [{
                amount: {
                    currency_code: "EUR",
                    value: order.price.toString()
                },
            }],
        });
    }

    if (getLoading)
        return <Typography variant='h4'>Loading...</Typography>

    return (
            <PayPalButtons
            style={{ layout: "vertical", shape: 'pill' }}
            createOrder={(data, actions) => onCreateOrder(data, actions)}
            onApprove={(data, actions) =>
            {
                return actions.order.capture().then(details =>
                {
                    console.log(details);
                    onSuccess(details);
                });
            }}
            onError={(err) =>
            {
                onError(err);
            }}
            />
    );
};

export default PayPalTokenOrderButton;
