import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const InstallPWAButton = () =>
{
    const navigate = useNavigate();

    const [isInstallable, setIsInstallable] = useState(false);

    useEffect(() =>
    {
        // Check if the deferredPrompt is already stored globally
        if (window.deferredPrompt)
        {
            setIsInstallable(true);
        }
    }, []);

    const handleInstallClick = async () =>
    {
        const deferredPrompt = window.deferredPrompt;
        if (deferredPrompt)
        {
            deferredPrompt.prompt(); // Show the install prompt
            const { outcome } = await deferredPrompt.userChoice;
            console.log(`User response to the install prompt: ${outcome}`);

            if (outcome === 'dismissed')
            {
                console.log('User dismissed installation');
            }
            else
            {
                window.deferredPrompt = null; // Clear it after use
                setIsInstallable(false); // Hide the install button
                navigate("/", { replace: true });
            }
        }
    };

    return (
        isInstallable && (
            <Button variant='bright' onClick={handleInstallClick}>
                Tap to Install
            </Button>
        )
    );
};

export default InstallPWAButton;
