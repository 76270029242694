import TokenRoundedIcon from '@mui/icons-material/TokenRounded';
import { Paper, Stack, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';

const TokensDisplay = React.forwardRef(function TokensDisplay(props, ref)
{
    const user = useSelector(getUser);
    const [tokens, setTokens] = useState(0);

    useEffect(() =>
    {
        setTokens(user.tokens ? user.tokens : 0);
        // cleanup function
        return () =>
        {
        };
    }, [user]);

    return (
        <Button variant='base_button'>
        <Paper variant='tokensDisplay' elevation={0} {...props} ref={ref}>
            <Stack direction='row' spacing={1}>
                <TokenRoundedIcon />
                <Typography>{tokens}</Typography>
            </Stack>
            </Paper>
        </Button>
    );
});

export default TokensDisplay; 
