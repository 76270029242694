import TokenRoundedIcon from '@mui/icons-material/TokenRounded';
import { Button, Stack, Typography, FormControl } from '@mui/material';
import * as React from 'react';
import Spacer from '../common/Spacer';

export default function TopUpTokens(props)
{
    return (
        <Button variant='top_up' fullWidth onClick={() => props.onClick({ tokens: props.tokens, price: props.price })}>
            <Stack direction='row' alignItems='center'>
                <Typography variant="h2">{props.tokens}</Typography>
                <TokenRoundedIcon color='white' />
            </Stack>
            <FormControl fullWidth />
            <Stack direction='row' alignItems='center'>
                <Typography variant="tokens_save" sx={{width:65} }>{props.save}</Typography>
                <Typography variant="h4">{props.currency}{props.price}</Typography>
            </Stack>
        </Button>
    );
}