import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL } from '@ffmpeg/util';
import { Typography } from '@mui/material';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { APP_URL } from './config';

const FFmpegContext = createContext(null);

export const useFFmpeg = () =>
{
    return useContext(FFmpegContext);
};

export const FFmpegProvider = ({ children }) =>
{
    const [ffmpeg, setFfmpeg] = useState(null);
    const [ffmpegIsLoaded, setIsLoaded] = useState(false);
    const [ffmpegProgress, setLoadingProgress] = useState(0);
    
    const ffmpegLoad = async () =>
    {
        const baseURL = APP_URL;
        const ffmpegInstance = new FFmpeg();
        ffmpegInstance.on('log', (message) =>
        {
            //console.log('onLog: ' + message);
        });

        //TODO: try catch, offer reload if it fails
        await ffmpegInstance.load({
            coreURL: await toBlobURL(`${baseURL}ffmpeg-core.js`, 'text/javascript'),
            wasmURL: await toBlobURL(`${baseURL}ffmpeg-core.wasm`, 'application/wasm', true, onWasmLoadingProgress),
        });
        setFfmpeg(ffmpegInstance);
        setIsLoaded(true);
    };

    const onWasmLoadingProgress = message =>
    {
        setLoadingProgress(Math.round(100 * (message.received / 32129114)));
    }

    return (
        <FFmpegContext.Provider value={{ ffmpeg, ffmpegLoad, ffmpegIsLoaded, ffmpegProgress }}>
            {children}
        </FFmpegContext.Provider>
    );
};
