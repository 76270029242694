import { FREE_TRIAL_DAYS, ROLES } from './config';
import { store } from './store';
import dayjs from 'dayjs';

export const getFreeTrialDaysLeft = () =>
{
    const user = store.getState().user.user;
    const userCreated = dayjs(user.created);
    const today = dayjs();

    const daysLeft = FREE_TRIAL_DAYS - today.diff(userCreated, 'day');

    return daysLeft;
}