import { PayPalButtons } from '@paypal/react-paypal-js';
import React from 'react';
import { PAYPAL_SUBSCRIPTION_PLAN_ID } from '../../app/config';

const PayPalSubscribeButton = ({ onSuccess, onError }) =>
{
    
    const onCreateSubscription = (data, actions) =>
    {
        return actions.subscription.create({
            plan_id: PAYPAL_SUBSCRIPTION_PLAN_ID
        });
    }
    
    return (
            <PayPalButtons
                style={{ layout: "vertical", shape: 'pill' }}
                createSubscription={(data, actions) => onCreateSubscription(data, actions)}
                onApprove={(data, actions) =>
                {
                    console.log(data);
                    onSuccess(data);
                }}
                onError={(err) =>
                {
                    onError(err);
                }}
            />
    );
};

export default PayPalSubscribeButton;
