import { Alert, AlertTitle, Backdrop, Button, Container, Stack, Snackbar, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getError, setError } from '../user/UserSlice';
import Cookies from 'js-cookie';
import Spacer from '../common/Spacer';

export default function TSDialog(props)
{
    const [accepted, setAccepted] = useState(false);

    useEffect(() =>
    {
        // Check if the cookie is already set
        const cookie = Cookies.get('privacyPolicyAccepted');
        if (cookie)
        {
            setAccepted(true);
        }
    }, []);

    const handleAccept = () =>
    {
        // Set the cookie for 6 months
        Cookies.set('privacyPolicyAccepted', 'true', { expires: 180 });
        setAccepted(true);
    };

    if (accepted)
    {
        return null; // Hide the privacy policy notice if accepted
    }

    return (
        <Snackbar open={true}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
            <Alert
                severity="info"
                variant="filled"
                sx={{ width: '100%' }}
            >
                <AlertTitle><Typography variant="h4">Terms of Service & Privacy Policy</Typography></AlertTitle>
                <Stack>
                    <Spacer />
                    <Typography variant="h6">By continuing to visit this site you agree to our <a rel='noreferrer' target='_blank' href='https://olivya.ai/terms-of-service/'>Terms of Service</a> & <a rel='noreferrer' target='_blank' href='https://olivya.ai/privacy-policy/'>Privacy Policy</a>.</Typography>
                    <Spacer />
                    <Button onClick={handleAccept} variant='login' sx={{ width: 150, height: 40 }}>Continue</Button>
                    <Spacer />
                </Stack>
            </Alert>
        </Snackbar>
    );
}
