import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import SessionsTimelineItem from './SessionsTimelineItem';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';

export default function SessionsTimeline(props)
{
    const user = useSelector(getUser);
    dayjs.extend(relativeTime);
    const daysUntilNextSunday = (7 - dayjs().day()) % 7 || 7;
    
    const getSessionColor = status =>
    {
        switch (status)
        {
            case "completed": return "lightGreen";
            case "abandoned": return "lightRed";
            case "started": return "lightestBlue";
            default: return "lightestBlue";
        }
    }

    const getSessionIcon = status =>
    {
        switch (status)
        {
            case "completed": return "CheckOutlinedIcon";
            case "abandoned": return "ClearOutlinedIcon";
            case "started": return "AddOutlinedIcon";
            default: return "AddOutlinedIcon";
        }
    }

    return (
        <Timeline sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.4,
            },
            padding: '0px',
        }}>
            
            <SessionsTimelineItem
                when=""
                title="START NEW SESSION"
                color="white"
                icon="PlayArrowOutlinedIcon"
                buttonVariant="bright"
                onClick={() => props.onLoadSession({ id: 0, status: 'started' })}
            />
            {props.sessions.map(session => (
                <SessionsTimelineItem
                    key={session.id}
                    when={dayjs(session.created).fromNow()}
                    title={session.short_summary}
                    color={getSessionColor(session.status)}
                    icon={getSessionIcon(session.status)}
                    buttonVariant="timeline_button"
                    onClick={() => props.onLoadSession(session)}
                    onDelete={() => props.onDeleteSession(session)}
                />))}
            <SessionsTimelineItem
                when={dayjs(user.created).fromNow()}
                title="Your journey with Olivya started"
                color="primary"
                buttonVariant="timeline_button"
            />
            
        </Timeline>
    );
}
