import { Paper, Stack, Typography } from '@mui/material';
import { default as React } from 'react';

function AccountStat(props)
{
    return (
        <Paper variant='myMessage'>
            <Stack sx={{ alignItems: 'center' }} spacing={1} >
                <Typography variant='account_stat'>{props.label}</Typography>
                <Typography variant='h2'>{props.value}</Typography>
            </Stack>
        </Paper>
    );
}

export default AccountStat;
