import { Box, Paper, Stack, Typography } from '@mui/material';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import dayjs from 'dayjs';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { am_logEvent } from '../../app/amplitude';
import { PAYPAL_CLIENT_ID, ROLES } from '../../app/config';
import { fs_addSubscription, fs_updateUser } from '../../app/firestore';
import { getUser, setUserProperties } from '../user/UserSlice';
import PayPalSubscribeButton from './PayPalSubscribeButton';


export default function PayPalSubscribe({ onFail, onSuccess })
{
    const user = useSelector(getUser);
    const dispatch = useDispatch();

    const [getWindowState, setWindowState] = React.useState('start');
    const [getOrder, setOrder] = React.useState({ tokens: 0, price: 0 });

    const onTopUpClick = order =>
    {
        setOrder(order);
        setWindowState('order');
    }

    const onCancelOrder = () =>
    {
        setOrder({ tokens: 0, price: 0 });
        setWindowState('start');
    }

    const onSuccessSubscription = (details) =>
    {
        setWindowState('processing');
        
        const fsSubscription = {
            user_id: user.uid,
            order_id: details.orderID,
            subscription_id: details.subscriptionID,
            payment_source: details.paymentSource,
            create_time: dayjs().format(),
            check_time: dayjs().add(30, 'day').format(),
            status: 'active'
        };

        fs_addSubscription(fsSubscription, () =>
        {
            const newUser = {
                role: ROLES.SUBSCRIBER,
                subscriptionId: fsSubscription.subscription_id,
                subscriptionCheckTime: fsSubscription.check_time
            };
            fs_updateUser(newUser, () =>
            {
                dispatch(setUserProperties(newUser));
                am_logEvent('purchase',
                    {
                        value: 29.99,
                        currency: 'EUR',
                        transaction_id: details.orderId,
                        items: [{
                            item_id: 'subscripton',
                            item_name: 'subscripton',
                        }]
                    });
                setWindowState('success');
            });
        });
    }

    const onError = err =>
    {
        setWindowState('error');
    }

    return (
        <Box sx={{ p: 0 }}>
            {getWindowState === 'start' &&
                (
                    <Paper variant='olivyaMessage'>
                        <Stack alignItems='flex-start' spacing={1} sx={{ maxWidth: '350px' }}>
                            <Typography variant='h3'>Become a Subscriber!</Typography>
                            <Typography variant='session_summary'>With Premium Subscription you get</Typography>
                            <Typography variant='session_summary'>FREE SESSION every day!</Typography>
                            <Typography variant='h6'>Only €29.99 per month</Typography>
                            <Box sx={{ backgroundColor: '#FFF', p: 2, borderRadius: '15px' }}>
                                <PayPalScriptProvider options={
                                    {
                                        "client-id": PAYPAL_CLIENT_ID,
                                        "currency": "EUR",
                                        "vault": "true",
                                        "intent": "subscription"
                                    }}>
                                    <PayPalSubscribeButton onSuccess={onSuccessSubscription} onError={onError} />
                                </PayPalScriptProvider>
                            </Box>
                        </Stack>
                    </Paper>
                )}
            {getWindowState === 'success' &&
                (
                    <Paper variant='olivyaMessage'>
                        <Stack alignItems='flex-start' spacing={1} sx={{ maxWidth: '350px' }}>
                            <Typography variant='h2'>You are Subscribed!</Typography>
                            <Typography variant='session_summary'>Enjoy your benefits!</Typography>
                        </Stack>
                    </Paper>
                )}
            {getWindowState === 'error' &&
                (
                    <Stack alignItems='flex-start' spacing={1} sx={{ maxWidth: '350px' }}>
                        <Paper variant='order_error'>
                            <Typography variant='h2'>There was an error!</Typography>
                            <Typography variant='h3'>Please contact support</Typography>
                        </Paper>
                    </Stack>
                )}
            {getWindowState === 'processing' &&
                (
                    <Stack alignItems='flex-start' spacing={1} sx={{ maxWidth: '350px' }}>
                        <div className="loading-screen-session">
                            <div className="loading-spinner">
                                <svg viewBox="0 0 100 100">
                                    <circle cx="50" cy="50" r="40" />
                                </svg>
                            </div>
                            <Typography variant="h4">Processing subscription...</Typography>
                        </div>
                        <Typography variant='h6' color='error'>DO NOT LEAVE OR REFRESH THIS PAGE!</Typography>
                    </Stack>
                )}
        </Box>
    );
}