import * as React from 'react';

export default function Spacer(props)
{
    let newHeight = 20;
    if (props.height !== undefined)
        newHeight = props.height;

    let newWidth = 20;
    if (props.width !== undefined)
        newWidth = props.width;

    return (
        <div style={{ width: newWidth, height: newHeight }} ></div>
    );
}