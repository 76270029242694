import { Checkbox, Stack, ToggleButton, Typography } from '@mui/material';
import { default as React } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Spacer from '../common/Spacer';

function AnswerCheckBox(props)
{
    return (
        <ToggleButton variant='answer_checkbox' value={props.label} sx={{ justifyContent: 'left' }} >
            <Stack direction='row' alignItems='center'>
                <Checkbox disabled checked={props.value !== undefined && props.value.includes(props.label)} />
                <Typography variant='survey_question'>{props.label}</Typography>
                <Spacer width={20} />
            </Stack>
        </ToggleButton>
    );
}

export default AnswerCheckBox;
