import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import { Button, Grid, Stack } from '@mui/material';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import { theme } from '../../app/styles';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

export default function SessionsTimelineItem(props)
{
    const getIcon = name =>
    {
        switch (name)
        {
            case "CheckOutlinedIcon": return <CheckOutlinedIcon sx={{ color: theme.palette[props.color].main }} />;
            case "InsightsOutlinedIcon": return <InsightsOutlinedIcon sx={{ color: theme.palette[props.color].main }} />;
            case "LockOutlinedIcon": return <LockOutlinedIcon sx={{ color: theme.palette[props.color].main }} />;
            case "PlayArrowOutlinedIcon": return <PlayArrowOutlinedIcon sx={{ color: theme.palette[props.color].main }} />;
            case "AddOutlinedIcon": return <AddOutlinedIcon sx={{ color: theme.palette[props.color].main }} />;
            case "ClearOutlinedIcon": return <ClearOutlinedIcon sx={{ color: theme.palette[props.color].main }} />;
            default: return <FiberManualRecordOutlinedIcon sx={{ color: theme.palette[props.color].main }} />
        }
    }

    return (
        <TimelineItem >
                <TimelineOppositeContent
                    sx={{ m: 'auto 0', padding: '0px 10px 0px 0px' }}
                    align="left"
                    variant="timeline_date"
                >
                <Button onClick={props.onDelete} variant="timeline_button"><DeleteForeverRoundedIcon /></Button>
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineConnector sx={{ bgcolor: theme.palette[props.color].main }} />
                <TimelineDot color={props.color} variant="outlined" onClick={props.onClick}>
                    {getIcon(props.icon)}
                    </TimelineDot>
                <TimelineConnector sx={{ bgcolor: theme.palette[props.color].main }} />
                </TimelineSeparator>
                <TimelineContent sx={{ m: 'auto 0', lineHeight: '1' }}>
                <Button variant={props.buttonVariant} onClick={props.onClick}>
                    <Stack spacing={-1}>
                        <Typography variant="timeline_title" component="span" sx={{ color: theme.palette[props.color].main, padding:'5px 0px 5px 0px' }}>
                            {props.title}
                    </Typography>
                    <Typography variant="timeline_date" component="span" sx={{ padding: '5px 0px 5px 0px' }}>
                        {props.when}
                        </Typography>
                    </Stack>
                </Button>
                </TimelineContent>
            </TimelineItem> 
    );
}
