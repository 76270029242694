import React, { useEffect, useRef } from 'react';

const PreventPullToRefresh = () =>
{
    const startYRef = useRef(null);

    useEffect(() =>
    {
        const preventDefault = (e) =>
        {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            const currentY = e.touches[0].clientY;

            // Check if startYRef has been set, if not, set it to currentY
            if (startYRef.current === null)
                startYRef.current = currentY;

            // Determine scroll direction
            const scrollDirection = currentY > startYRef.current ? 'down' : 'up';

            // Only prevent pull-to-refresh if scrollTop is 0 (at the top of the page) and scrolling down
            if (scrollTop === 0 && scrollDirection === 'down')
                e.preventDefault();

            // Update startYRef to currentY for next touchmove event
            startYRef.current = currentY;
        };
        

        const touchstartHandler = (e) =>
        {
            startYRef.current = e.touches[0].clientY; // Initialize startYRef with touch start position
            window.addEventListener('touchmove', preventDefault, { passive: false });
        };

        const touchendHandler = () =>
        {
            startYRef.current = null; // Reset startYRef on touch end
            window.removeEventListener('touchmove', preventDefault, { passive: false });
        };

        window.addEventListener('touchstart', touchstartHandler, { passive: false });
        window.addEventListener('touchend', touchendHandler, { passive: false });
        window.addEventListener('touchcancel', touchendHandler, { passive: false });

        // Cleanup event listeners on component unmount
        return () =>
        {
            window.removeEventListener('touchstart', touchstartHandler);
            window.removeEventListener('touchend', touchendHandler);
            window.removeEventListener('touchcancel', touchendHandler);
            window.removeEventListener('touchmove', preventDefault);
        };
    }, []);

    return null; // This component doesn't render anything
};

export default PreventPullToRefresh;
