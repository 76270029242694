import { default as React } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import AppRoutes from './AppRoutes';
import { getTimeOfDayCSSClass } from './app/dateUtils';
import PreventPullToRefresh from './features/common/PreventPullToRefresh';
import ErrorDialog from './features/dialog/ErrorDialog';

function App()
{
    return (
        <div style={{
            minHeight: "100vh",
        }} className={getTimeOfDayCSSClass()}>
            <PreventPullToRefresh />
            <Router>
                <AppRoutes />
                <ErrorDialog />
            </Router>
        </div>
    );
}

export default App;
