import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuIcon from '@mui/icons-material/Menu';
import TryRoundedIcon from '@mui/icons-material/TryRounded';
import { Button, Divider, ListItemIcon, Menu, MenuItem, Stack, Badge } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isoWeek from 'dayjs/plugin/isoWeek';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { getAuth, signOut } from "firebase/auth";
import { default as React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROLES, VERSION } from '../../app/config';
import { getTimeOfDayText } from '../../app/dateUtils';
import Spacer from '../common/Spacer';
import DailySessionTimer from '../olivya/DailySessionTimer';
import { getUser, setWeeklyReport } from '../user/UserSlice';
import PieCanvas from './PieCanvas';
import TokensDisplay from './TokensDisplay';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);
dayjs.extend(isToday);
dayjs.extend(localizedFormat);

function ResponsiveAppBar(props)
{
    const user = useSelector(getUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let timeOfDay = getTimeOfDayText();

    //Difference in seconds since creation of the session
    const [maxTimerTicks, setMaxTimerTicks] = useState(86400);
    const [timerTicks, setTimerTicks] = useState(86400 - Math.floor(dayjs().diff(dayjs().add(1, 'day').startOf('day')) / 1000));
    const [timer, setTimer] = useState(0);


    useEffect(() =>
    {
        createWeeklyObjects();
        if (props.sessions.length > 0 && dayjs(props.sessions[0].created).isSame(dayjs(), 'day'))
        {
            clearInterval(timer);
            setTimerTicks(86400 - Math.floor(dayjs().add(1, 'day').startOf('day').diff(dayjs()) / 1000));
            let timerObj = setInterval(() =>
            {

                setTimerTicks(prevTicks =>
                {
                    const newTicks = prevTicks + 1;
                    // reload session when new day starts
                    if (newTicks === 86400)
                        props.onTimerComplete();

                    return newTicks;
                });
            }, 1000);
            setTimer(timerObj);
        }
        else
        {
            clearInterval(timer);
            setTimerTicks(maxTimerTicks);
        }

        // cleanup function
        return () =>
        {
            clearInterval(timer);
        }
    }, [props.sessions]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) =>
    {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () =>
    {
        setAnchorEl(null);
    };

    const handleLogout = async () =>
    {
        setAnchorEl(null);
        const auth = getAuth();
        try
        {
            await signOut(auth);
            console.log('User successfully logged out');
            navigate("/login", { replace: true });

        } catch (error)
        {
            console.error('Error logging out:', error);
        }
    };

    const [weeklyObjects, setWeeklyObjects] = useState([]);

    const createWeeklyObjects = () =>
    {

        // Initialize an array for the days of the current week starting from Monday
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const daysOfWeekShort = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
        const today = dayjs();
        let startOfWeek = today.startOf('week');
        // If it's Sunday, set the start of Week to the previous one, to show the streak on Sunday
        if (startOfWeek.isSame(today, 'day'))
            startOfWeek = today.subtract(7, 'day');


        // Create an array to hold sessions grouped by days of the week
        const groupedSessions = daysOfWeek.reduce((acc, day, index) =>
        {
            acc.push({
                day, session: { status: 'empty' }, item: (
                    <PieCanvas
                        key={"item" + index}
                        percent={0}
                        label={daysOfWeekShort[index]}
                        countdownColor='#FFFFFF50'
                        borderColor={today.format('d') === (index + 1).toString() ? '#FFFFFF' : '#FFFFFF50'}
                        radius={14}
                    />)
            });

            return acc;
        }, []);

        // Iterate through sessions and group them by day of the week
        props.sessions.forEach(session =>
        {
            const sessionDate = dayjs(session.created);

            if (sessionDate.isSameOrBefore(today, 'day') && sessionDate.isAfter(startOfWeek, 'day'))
            {
                const dayOfWeek = sessionDate.isoWeekday() - 1; // Get the index (0 for Monday, 6 for Sunday)
                //if it's not Sunday (we dont want to draw Sunday), draw the pie for the day of the week
                if (dayOfWeek < 6 && groupedSessions[dayOfWeek].session.status !== 'completed') 
                {
                    groupedSessions[dayOfWeek].session = session;
                    groupedSessions[dayOfWeek].item = (
                        <PieCanvas
                            key={session.created}
                            percent={session.status === 'empty' ? 0 : 1}
                            label={daysOfWeekShort[dayOfWeek]}
                            borderColor={sessionDate.isSame(today, 'day') ? '#FFFFFF' : getWeeklyColor(session)}
                            countdownColor={getWeeklyColor(session)}
                            radius={14}
                        />);

                }
            }
        });
        setWeeklyObjects(groupedSessions);
    }


    const getWeeklyColor = (session) =>
    {
        if (session.status === 'empty')
            return '#334E81';
        if (session.status === 'completed')
            return '#2e9457';
        if (session.status === 'abandoned')
            return '#555555';
        if (session.status === 'started')
            return '#507BC9';
    }

    const onWeeklyReportClick = week =>
    {
        if (dayjs().isoWeekday() === 7)
        {
            dispatch(setWeeklyReport(week));
            navigate("/report", { replace: true });
        }
    }

    return (
        <AppBar position="relative"
            sx={{
                borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px',
                padding: 2, background: 'transparent',
            }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Stack direction="row">
                    <IconButton sx={{ p: 0 }} onClick={() => navigate("/account", { replace: true })}>
                        <Avatar alt={user.name} src={user.photoURL} sx={{ border: '3px solid #E272DC' }} referrerPolicy="no-referrer" />
                    </IconButton>
                    <Spacer width={15} />
                    <div>
                        <Typography variant="h3">Good {timeOfDay},</Typography>
                        <Typography variant="h3">{user.name.split(' ')[0]}</Typography>
                    </div>

                </Stack>


                <IconButton size="large" onClick={handleClick}>
                    {props.isBrowser && <Badge color="error" variant='dot'>
                        <MenuIcon />
                    </Badge>}
                    {!props.isBrowser && <MenuIcon />}
                </IconButton>
            </Grid>
            <Menu
                anchorEl={anchorEl}
                id="main-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

                <MenuItem onClick={() => navigate("/account", { replace: true })}>
                    <Avatar alt={user.name} src={user.photoURL} referrerPolicy="no-referrer" /> Account
                </MenuItem>
                <Divider />
                {props.isBrowser &&
                    <MenuItem onClick={() => navigate("/tutorial_installpwa", { replace: true })}>
                        <ListItemIcon>
                            <Badge color="error" variant='dot'>
                                <FileDownloadOutlinedIcon />
                            </Badge>
                        </ListItemIcon>
                        Install App!
                    </MenuItem>}
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutRoundedIcon />
                    </ListItemIcon>
                    Logout
                </MenuItem>
                <Divider />
                <MenuItem>
                    <Typography variant='caption'>{VERSION}</Typography>
                </MenuItem>
                {user.role === ROLES.ADMIN && (
                    <MenuItem onClick={() => navigate("/admin_sessions", { replace: true })}>
                        <ListItemIcon>
                            <TryRoundedIcon />
                        </ListItemIcon>
                        ADMIN: Sessions
                    </MenuItem>)}
            </Menu>

            <Spacer />
            <Grid container
                direction="row"
                justifyContent="space-around"
                alignItems="center">
                {weeklyObjects.map(wObject => (wObject.item))}
                <Button variant='base_button'>
                    <PieCanvas
                        onClick={() => onWeeklyReportClick(dayjs().week() - 1)}
                        percent={dayjs().isoWeekday() === 7 ? 1 : 0}
                        label="Weekly Insights"
                        borderColor="#c471f5"
                        countdownColor="#c471f5"
                        radius={18} borderSize={4} />
                </Button>
            </Grid>
            <Spacer />
            <Stack direction='row' spacing={1} justifyContent="space-between">
                <DailySessionTimer variant="determinate" currentValue={timerTicks} maxValue={maxTimerTicks} />
                <TokensDisplay onClick={props.onTokensClick} />
            </Stack>
        </AppBar>
    );
}
export default ResponsiveAppBar;
