import { default as React } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import './App.css';
import Account from './features/account/Account';
import AdminSession from './features/admin_sessions/AdminSession';
import AdminSessions from './features/admin_sessions/AdminSessions';
import LoadAdminSession from './features/admin_sessions/LoadAdminSession';
import Login from './features/login/Login';
import Olivya from './features/olivya/Olivya';
import Onboarding from './features/onboarding/Onboarding';
import Report from './features/report/Report';
import Session from './features/session/Session';
import SessionCompleted from './features/session/SessionCompleted';
import Statistics from './features/statistics/Statistics';
import TutorialInstallPWA from './features/tutorial_installpwa/TutorialInstallPWA';

function AppRoutes()
{
    const location = useLocation();

    return (
        <TransitionGroup component={null}>
            <CSSTransition key={location.key} classNames="fade" timeout={300}>
                <Routes location={location}>
                    <Route path="/" element={<Olivya />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/onboarding" element={<Onboarding />} />
                    <Route path="/session" element={<Session />} />
                    <Route path="/session_completed" element={<SessionCompleted />} />
                    <Route path="/report/:week" element={<Report />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/admin_sessions" element={<AdminSessions />} />
                    <Route path="/load_admin_session" element={<LoadAdminSession />} />
                    <Route path="/admin_session" element={<AdminSession />} />
                    <Route path="/statistics" element={<Statistics />} />
                    <Route path="/tutorial_installpwa" element={<TutorialInstallPWA />} />

                    {
                    //<Route path="/admin_preprompt" element={<AdminPreprompt />} />
                    }

                        </Routes>
                    </CSSTransition>
                </TransitionGroup>
    );
}

export default AppRoutes;
