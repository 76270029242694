import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Button, Stack, IconButton, Typography } from '@mui/material';
import { useWavesurfer } from '@wavesurfer/react';
import React, { useEffect, useRef, useState } from "react";
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js'

function VAD(props)
{
    const [isAudioLoaded, setIsAudioLoaded] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [recorder, setRecorder] = useState(null);

    const [elapsedRecordTime, setElapsedRecordTime] = useState('00:00');

    const containerRef = useRef(null);

    const formatTime = (time) => [
        Math.floor((time % 3600000) / 60000), // minutes
        Math.floor((time % 60000) / 1000), // seconds
    ]
        .map((v) => (v < 10 ? '0' + v : v))
        .join(':');

    const { wavesurfer, currentTime } = useWavesurfer(
        {
            "container": containerRef,
            //"backend": 'WebAudio', // add this too 
            "height": 24,
            "splitChannels": false,
            "normalize": true,
            "waveColor": "#9BBEFF",
            "progressColor": "#9BBEFF",
            "cursorColor": "#FFFFFF",
            "cursorWidth": 1,
            "barWidth": 4,
            "barGap": 3,
            "barRadius": 30,
            "barHeight": 4,
            "barAlign": "",
            "minPxPerSec": 1,
            "fillParent": true,
            //"url": audioURL,
            "mediaControls": false,
            "autoplay": false,
            "interact": true,
            "dragToSeek": false,
            "hideScrollbar": true,
            "audioRate": 1,
            "autoScroll": true,
            "autoCenter": true,
            "sampleRate": 8000,
            
        }
    );

    useEffect(() =>
    {
        if (recorder)
        if (props.isRecording)
        {
            if (recorder.isRecording())
                recorder.stopRecording();

            recorder.startRecording();
        }
        else
        {
            recorder.stopRecording();
        }

    }, [props.isRecording]);

    useEffect(() =>
    {
        if (wavesurfer)
        {
            wavesurfer.on('play', () =>
            {
                setIsPlaying(true);
            });

            wavesurfer.on('pause', () =>
            {
                setIsPlaying(false);
            });

            // Initialize the Record plugin
            let record = wavesurfer.registerPlugin(RecordPlugin.create({ scrollingWaveform: true, renderRecordedAudio: true }))
            // Render recorded audio
            record.on('record-end', (blob) =>
            {
                props.onAudioRecorded(blob);
                setIsAudioLoaded(true);
            });

            record.on('record-progress', (time) =>
            {
                setElapsedRecordTime(formatTime(time));
            });

            setRecorder(record);
        }

    }, [wavesurfer]);

    const onPlayPause = () =>
    {
        wavesurfer && wavesurfer.playPause();
    }

    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={0}
        >
            {(!props.isRecording && isAudioLoaded) &&
                (<Button onClick={onPlayPause} variant="playpause_olivya">
                    {!isPlaying ? <PlayArrowIcon /> : <PauseIcon />}
                </Button>)}
            {!isAudioLoaded && <div></div>}
            {props.isRecording && <IconButton><div className="pulsating-record" /></IconButton>}
            <Box
                id='my-audio-id'
                name='my-audio-name'
                key='my-audio-key'
                ref={containerRef}
                sx={{ width: '100%' }} />
            {props.isRecording && <Typography>{elapsedRecordTime}</Typography>}
        </Stack>
    );
    
            
}

export default VAD;