// Import the functions you need from the SDKs you need
import * as Sentry from "@sentry/react";
import dayjs from 'dayjs';
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { am_logEvent, am_setUserId } from './amplitude';
import { ROLES, SESSION_SECONDS } from './config';
import { showError } from './showError';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDpPWdlw1BYaQSe24ec1F9IC8SQdaExSKI",
    authDomain: "olivya-531a4.firebaseapp.com",
    projectId: "olivya-531a4",
    storageBucket: "olivya-531a4.appspot.com",
    messagingSenderId: "219068446862",
    appId: "1:219068446862:web:f33891e89d5d708dfaf1df",
    measurementId: "G-4M6MLVJ03W",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore();

export const ga_logEvent = (event, data) =>
{
    logEvent(analytics, event, data);
}

export const fs_loginUser = async (callback) =>
{
    const user = auth.currentUser;
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists())
    {
        // User doesn't exist, create a new entry
        const userData = {
            uid: user.uid,
            name: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            role: ROLES.GUEST,
            tokens: 40,
            started_sessions: 0,
            completed_sessions: 0,
            created: dayjs().format(),
            onboarding: true,
        };
        await setDoc(userRef, userData);
        callback(userData);
    } else
    {
        // User exists, return their info
        const newUser = userDoc.data();
        if (newUser.tokens === undefined)
            newUser.tokens = 5;

        callback(newUser);
    }

    setUserId(analytics, user.uid);
    am_setUserId(user.uid);
    am_logEvent('login', { method: 'Google' });
};

export const fs_updateUser = async (value, callback) =>
{
    const user = auth.currentUser;
    try
    {
        const sessionRef = doc(db, 'users', user.uid);
        await updateDoc(sessionRef, value);
    } catch (error)
    {
        Sentry.captureException(error);
        showError("updateUser: " + error.toString());
        console.error('updateUser: ', error);
    }
    callback();
}

export const fs_updateUserProperty = async (property, value, callback) =>
{
    const user = auth.currentUser;
    try
    {
        const sessionRef = doc(db, 'users', user.uid);
        await updateDoc(sessionRef, {
            [property]: value
        });
    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_updateUserProperty: " + error.toString());
        console.error('Error updating user property: ', error);
    }
    callback();
}

export const fs_createSession = async (callback) =>
{
    const user = auth.currentUser;
    try
    {
        const defaultSession = {
            userId: user.uid,
            created: dayjs().format(),
            expires: dayjs().add(SESSION_SECONDS, 'second').format(),
            status: 'abandoned',
            rating: 0,
            stars: 0,
            short_summary: 'I didn\'t complete this session',
        };
        const res = await addDoc(collection(db, 'sessions'), defaultSession);

        defaultSession.id = res.id;

        /* // For Assistant API, but not used for now
        ai_createThread(data =>
        {
            defaultSession.threadId = data.id;
            callback(defaultSession);
        })*/
        callback(defaultSession);


    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_createSession: " + error.toString());
        console.error("fs_createSession: ", error);
    }
}

export const fs_loadSession = async (sessionId, callback) =>
{
    try
    {
        const sessionRef = doc(db, 'sessions', sessionId);
        const sessionSnap = await getDoc(sessionRef);

        if (sessionSnap.exists())
        {
            const sessionData = { id: sessionSnap.id, ...sessionSnap.data() };
            callback(sessionData);
        } else
        {
            console.warn('No such document!');
            callback(null);
        }

    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_loadSession: " + error.toString());
        console.error("fs_loadSession: ", error);
    }
}

export const fs_loadSessions = async (numberOfSessions, callback) =>
{
    const user = auth.currentUser;
    //const user = { uid: 't3JVyXHdKXPyKMcT8d2XLCCAWks2' }; //Sophie

    try
    {
        const sessionsRef = collection(db, 'sessions');
        
        const q = query(
            sessionsRef,
            where('userId', '==', user.uid),
            where('created', '<=', dayjs().format()),
            orderBy('created', 'desc'),
            limit(numberOfSessions)
        );

        const querySnapshot = await getDocs(q);

        const sessions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        callback(sessions);

    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_loadSessions: " + error.toString());
        console.error("fs_loadSessions: ", error);
    }
};

export const fs_loadSessionsBetweenDates = async (startDate, endDate, callback) =>
{
    const user = auth.currentUser;

    try
    {
        const sessionsRef = collection(db, 'sessions');

        const q = query(
            sessionsRef,
            where('userId', '==', user.uid),
            where('created', '>=', startDate),
            where('created', '<=', endDate),
            orderBy('created', 'desc')
        );

        const querySnapshot = await getDocs(q);

        const sessions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        callback(sessions);

    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_loadSessionsBetweenDates: " + error.toString());
        console.error("fs_loadSessionsBetweenDates: ", error);
    }
};

export const fs_addSessionMessage = async (sessionId, message, callback) =>
{
    try
    {
        message.created = dayjs().format();
        const messagesRef = collection(db, 'sessions', sessionId, 'messages');
        await addDoc(messagesRef, message);
    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_addSessionMessage: " + error.toString());
        console.error("fs_addSessionMessage: ", error);
    }

    callback();
}

export const fs_updateSession = async (sessionId, value, callback) =>
{
    try
    {
        const sessionRef = doc(db, 'sessions', sessionId);
        await updateDoc(sessionRef, value);
    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_updateSession: " + error.toString());
        console.error('fs_updateSession: ', error);
    }
    callback();
}

export const fs_updateProperty = async (sessionId, property, value, callback) =>
{
    try
    {
        const sessionRef = doc(db, 'sessions', sessionId);
        await updateDoc(sessionRef, {
            [property]: value
        });
    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_updateProperty: " + error.toString());
        console.error('fs_updateProperty: ', error);
    }
    callback();
}

export const fs_getSessionMessages = async (sessionId, callback) =>
{
    try
    {
        const messagesRef = collection(db, 'sessions', sessionId, 'messages');
        const q = query(messagesRef, orderBy('created', 'asc'));
        const querySnapshot = await getDocs(q);

        const messages = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        callback(messages);
    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_getSessionMessages: " + error.toString());
        console.error('fs_getSessionMessages: ', error);
    }
};

export const fs_deleteSession = async (sessionId, callback) =>
{
    try
    {
        const sessionRef = doc(db, 'sessions', sessionId);
        await deleteDoc(sessionRef);
        callback();
    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_deleteSession: " + error.toString());
        console.error('fs_deleteSession: ', error);
    }
};

export const fs_loadPreprompts = async (callback) =>
{
    try
    {
        const sessionsRef = collection(db, 'preprompts');
        const q = query(sessionsRef, orderBy('created', 'desc'));
        const querySnapshot = await getDocs(q);

        const sessions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        callback(sessions);

    } catch (error)
    {
        showError("fs_loadPreprompts: " + error.toString());
        console.error('fs_loadPreprompts: ', error);
    }
}

export const fs_createPreprompt = async (callback) =>
{
    const user = auth.currentUser;
    try
    {
        const res = await addDoc(collection(db, 'preprompts'), {
            userId: user.uid,
            created: dayjs().format(),
            preprompt_title: 'Name your pre-prompt',
            preprompt_text: 'This is default text.',
            long_summary_text: '',
            short_summary_text: '',
            status: 'draft',
        });

        callback(res);

    } catch (error)
    {
        showError("fs_createPreprompt: " + error.toString());
        console.error('fs_createPreprompt: ', error);
    }
}

export const fs_updatePreprompt = async (prepromptId, value, callback) =>
{
    try
    {
        const sessionRef = doc(db, 'preprompts', prepromptId);
        await updateDoc(sessionRef, value);
    } catch (error)
    {
        showError("fs_updatePreprompt: " + error.toString());
        console.error('fs_updatePreprompt: ', error);
    }
    callback();
}

export const fs_loadLivePreprompt = async (callback) =>
{
    try
    {
        const sessionRef = doc(db, 'preprompts', 'FhFKV1mmPGAH1EYW9SrL'); //Load specific preprompt from firestore
        const sessionSnap = await getDoc(sessionRef);

        if (sessionSnap.exists())
        {
            const sessionData = { id: sessionSnap.id, ...sessionSnap.data() };
            callback(sessionData);
        } else
        {
            console.warn('No such document!');
            callback(null);
        }

    } catch (error)
    {
        showError("fs_loadLivePreprompt: " + error.toString());
        console.error('fs_loadLivePreprompt: ', error);
    }
}

export const fs_loadReport = async (created, callback) =>
{
    const user = auth.currentUser;
    //const user = { uid: 't3JVyXHdKXPyKMcT8d2XLCCAWks2' }; //Sophie
    try
    {
        const reportsRef = collection(db, 'reports');
        const q = query(reportsRef, where('created', '==', created), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty)
        {
            querySnapshot.forEach((doc) =>
            {
                callback({ id: doc.id, ...doc.data() });
            });
        } else
        {
            console.warn('No report with the specified "created" field found!');
            callback(null);
        }

    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_loadReport: " + error.toString());
        console.error("fs_loadReport: ", error);
    }
}

export const fs_createReport = async (report, callback) =>
{
    const user = auth.currentUser;
    try
    {
        const res = await addDoc(collection(db, 'reports'), report);
        callback(res);
    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_createReport: " + error.toString());
        console.error('fs_createReport: ', error);
    }
}

export const fs_addOrder = async (order, callback) =>
{
    try
    {
        const res = await addDoc(collection(db, 'orders'), order);
        callback(res);
    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_addOrder: " + error.toString());
        console.error('fs_addOrder: ', error);
    }
}

export const fs_addSubscription = async (order, callback) =>
{
    try
    {
        const res = await addDoc(collection(db, 'subscriptions'), order);
        callback(res);
    } catch (error)
    {
        Sentry.captureException(error);
        showError("addSubscription: " + error.toString());
        console.error('addSubscription: ', error);
    }
}

export const getAuthUser = async () =>
{
    return new Promise((resolve, reject) =>
    {
        const unsubscribe = auth.onAuthStateChanged(user =>
        {
            unsubscribe(); // Unsubscribe immediately after the first invocation
            resolve(user); // Resolve with the user object
        }, reject); // Reject with any errors
    });
};

export const fs_loadUsers = async (callback) =>
{
    try
    {
        const sessionsRef = collection(db, 'users');

        const q = query(
            sessionsRef,
            orderBy('created', 'asc')
        );

        const querySnapshot = await getDocs(q);

        const sessions = querySnapshot.docs.map(doc => ({ uid: doc.uid, ...doc.data() }));

        callback(sessions);

    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_loadUsers: " + error.toString());
        console.error("fs_loadUsers: ", error);
    }
};

export const fs_loadAllSessions = async (callback) =>
{
    try
    {
        const sessionsRef = collection(db, 'sessions');

        const q = query(
            sessionsRef,
            orderBy('created', 'asc')
        );

        const querySnapshot = await getDocs(q);

        const sessions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        for (var i = 0; i < sessions.length; i++)
        {
            try
            {
                const messagesRef = collection(db, 'sessions', sessions[i].id, 'messages');
                const q = query(messagesRef, orderBy('created', 'asc'));
                const querySnapshot = await getDocs(q);

                const messages = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                if(messages.length > 0)
                    sessions[i].messages = messages;


            } catch (error)
            {
                showError("fs_getSessionMessages: " + error.toString());
                console.error('fs_getSessionMessages: ', error);
            }
        }

        callback(sessions);

    } catch (error)
    {
        Sentry.captureException(error);
        showError("fs_loadSessions: " + error.toString());
        console.error("fs_loadSessions: ", error);
    }
};