import { Checkbox, Stack, Button, Typography, Box } from '@mui/material';
import { default as React, useRef } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Spacer from '../common/Spacer';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { theme } from '../../app/styles';
import Lottie from 'react-lottie';
import completeAnimation from '../../animations/confetti_3_animation.json';

function ActionCheckBox(props)
{
    const [isLottieVisible, set_isLottieVisible] = React.useState(false);
    //const lottieRef = useRef(null);

    const onClick = () =>
    {
        if (!props.isChecked)
        {
            set_isLottieVisible(true);
            //lottieRef.current?.play();
        }

        if (props.isChecked)
            set_isLottieVisible(false);
        
        props.onClick(props.sessionId, props.index, !props.isChecked);
    }

    return (
        <Button variant='action_button' sx={{ position: 'relative', top: '-10px' }}
            onClick={onClick}>
            <Checkbox
                checked={props.isChecked}
                sx={{
                    padding: props.size === 'small' ? '0px 4px 0px 12px' : '0px 10px 0px 0px',
                    color: theme.palette['warning'].main,
                    '&.Mui-checked': {
                        color: theme.palette['lightGreen'].main,
                    },
                }}
                icon={<RadioButtonUncheckedRoundedIcon />}
                checkedIcon={<CheckCircleOutlineRoundedIcon />}
                size={props.size}
            />
            <Typography variant={'action_title_' + props.size}>{props.label}</Typography>
            {isLottieVisible && <Box sx={{ position: 'absolute', left: '-27px' }}>
                <Lottie
                    isClickToPauseDisabled={true}
                    
                    options={{
                        loop: false,
                        autoplay: true,
                        animationData: completeAnimation,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={100}
                    width={100}
                />
            </Box>}
        </Button>
    );
}

export default ActionCheckBox;
