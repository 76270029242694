import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import { ROLES } from '../../app/config';
import { getFreeTrialDaysLeft } from '../../app/roleUtils';
function ProgressTimer({ currentValue, maxValue, ...props })
{
    const user = useSelector(getUser);
    const newValue = Math.ceil((currentValue / maxValue) * 100);

    return (
        <Box sx={{ display: 'flex', flexGrow: 100, alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1, position: 'relative' }}>
                <LinearProgress variant="determinate" value={newValue} {...props} />
            </Box>
        </Box>
    );
}

ProgressTimer.propTypes = {
    currentValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
};

const TutorialProgress = styled(ProgressTimer)(({ theme }) => ({
    height: 16,
    width: '100%',
    borderRadius: 20,
    border: '1px solid #ffffff50',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'transparent',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 0,
        backgroundColor: '#ffffff30',
    },
}));

export default TutorialProgress;