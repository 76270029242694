import { useEffect, useState } from 'react';

const ATCBEventsObserver = ({ buttonId, onSuccess }) =>
{
    const [lastEvent, setLastEvent] = useState('');

    useEffect(() =>
    {
        // Function to handle attribute changes
        const handleMutation = (mutationsList) =>
        {
            for (const mutation of mutationsList)
            {
                if (mutation.type === 'attributes' && mutation.attributeName === 'atcb-last-event')
                {
                    const updatedEvent = mutation.target.getAttribute('atcb-last-event');
                    setLastEvent(updatedEvent);

                    if (updatedEvent.startsWith('success'))
                    {
                        observer.disconnect();
                        onSuccess();
                        break;
                    }

                }
            }
        };

        // Set up the observer
        const observer = new MutationObserver(handleMutation);

        // Select the element to observe
        const targetElement = document.getElementById(buttonId);

        // Start observing the target element for changes to the 'atcb-last-event' attribute
        if (targetElement)
        {
            observer.observe(targetElement, { attributes: true });
        }

        // Clean up the observer when the component unmounts
        return () =>
        {
            if (targetElement)
            {
                observer.disconnect();
            }
        };
    }, [buttonId]); // Re-run effect if buttonId changes

    return (
        null
    );
};

export default ATCBEventsObserver;