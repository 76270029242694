import { AppBar, Grid, IconButton, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { default as React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fs_deleteSession, fs_loadSessions } from '../../app/firestore';
import Spacer from '../common/Spacer';
import { withAuthentication } from '../login/withAuthentication';
import { getUser, setSession } from '../user/UserSlice';
import SessionsTimeline from '../admin_sessions/SessionsTimeline';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import PrepromptSelector from './PrepromptSelector';

function AdminSessions(props)
{
    dayjs.extend(relativeTime);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);

    const [sessions, setSessions] = useState([]);
    const [extraSession, setExtraSession] = useState(false);
    
    useEffect(() =>
    {
        fs_loadSessions(100, onLoadSessions);
        // cleanup function
        return () =>
        {
        };
    }, []);

    const onLoadSessions = sessions =>
    {
        setSessions(sessions);
    }

    const loadSession = session =>
    {
        setExtraSession(false);
        dispatch(setSession(session));
        navigate("/load_admin_session", { replace: true });
    }

    const onDeleteSession = (session) =>
    {
        fs_deleteSession(session.id, () => { fs_loadSessions(100, onLoadSessions) });
    }

    const [openPurchaseDialog, setOpenPurchaseDialog] = React.useState(false);

    const handlePurchase = () =>
    {
        setOpenPurchaseDialog(false);
        setExtraSession(true);
    };

    return (
        <Container disableGutters maxWidth="sm">
            <AppBar position="sticky"
                sx={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', padding: 2, backgroundImage: 'none' }}
                color={props.status === 'completed' ? 'darkGreen' : 'lightBlue'}
                enableColorOnDark>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <IconButton onClick={e => navigate("/", { replace: true })} >
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>
                    <Typography variant="h5">ADMIN SESSIONS</Typography>
                    <Spacer width={50} />
                    
                </Grid>
            </AppBar>
            <SessionsTimeline
                extraSession={extraSession}
                sessions={sessions}
                onLoadSession={loadSession}
                onDeleteSession={onDeleteSession}
                onPurchaseSession={() => setOpenPurchaseDialog(true)} />    
            <Spacer height={120} />
            <Dialog
                open={openPurchaseDialog}
                onClose={() => setOpenPurchaseDialog(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='h3'>Pay for another session?</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant='h6'>Confirm this FAKE purchase</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPurchaseDialog(false)}>Cancel</Button>
                    <Button variant='contained' onClick={handlePurchase} autoFocus>
                        PURCHASE
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default withAuthentication(AdminSessions);
