import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { theme } from './app/styles';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from "@sentry/react";
import { FFmpegProvider } from './app/FFmpegContext';

// Register service worker
if ('serviceWorker' in navigator)
{
    window.addEventListener('load', () =>
    {
        navigator.serviceWorker.register('/service-worker.js')
            .then(registration =>
            {
                console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch(err =>
            {
                console.error('Service Worker registration failed:', err);
            });
    });
}

// Cache the isntall PWA event, so we can call it later in the TutorialInstallPWA page
window.deferredPrompt = null;

window.addEventListener('beforeinstallprompt', (e) =>
{
    e.preventDefault();
    window.deferredPrompt = e;
});

// Setup error loggin tool Sentry
Sentry.init({
    dsn: "https://0d9cd0d1cef8e4406c36a75e181e9b07@o4507424129679360.ingest.de.sentry.io/4507424131645520",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            networkDetailAllowUrls: ['https://staging.olivya.ai/phpbe/getMyMessage'],
            networkRequestHeaders: ['X-Custom-Header'],
            networkResponseHeaders: ['X-Custom-Header'],
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "https://talk.olivya.ai"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Initialise React
const container = document.getElementById('root');
const root = createRoot(container);

/*<React.StrictMode>*/

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ThemeProvider theme={theme}>
                    <FFmpegProvider>
                        <App />
                    </FFmpegProvider>
                </ThemeProvider>
            </LocalizationProvider>
        </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
