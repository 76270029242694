import { Box, Button, Container, Grid, Typography, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getTimeOfDayCSSClass } from '../../app/dateUtils';
import Spacer from '../common/Spacer';
import Lottie from 'react-lottie';
import circularLoader from '../../animations/loading_books_animation.json';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';

function LoadSession(props)
{
    const [isLoadingClicked, setIsLoadingClicked] = useState(false);

    const onStart = e =>
    {
        props.onClose();
        setIsLoadingClicked(true);
    }
    
    return (
        <div style={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 999999,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }} className={getTimeOfDayCSSClass()}>

            <Container disableGutters maxWidth="sm" >
                <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ height: '100vh' }}
                >

                    <Spacer height={40} />
                    <Typography variant="h3">{props.isLoaded ? 'Your session is ready!' : 'Loading ' + props.progress + '%'}</Typography>
                    {props.isLoaded && (<Button disabled={isLoadingClicked} variant="bright" onClick={onStart} >{isLoadingClicked ? "Starting..." : "Start Session"}</Button>)}
                    {!props.isLoaded && (<Lottie
                            isClickToPauseDisabled={true}
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: circularLoader,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice"
                                }
                            }}
                            height={216}
                            width={261}
                        />)}
                    <Box
                        direction="column"
                        justifyContent="flex-start"
                        width='80%'
                    >
                        <Typography variant="h4">Remember:</Typography>
                        <Spacer height={0} />
                        <Typography variant="session_summary">- The session lasts up to 30 minutes, but can be extended if needed.<br /><br />
                            - It's important to be in a quiet place for the best experience.<br /><br />
                            - Talk to Olivya like you would with a coach.<br /><br />
                            - This is your safe and private space!
                        </Typography>
                    </Box>
                    
                    <Spacer height={50} />
                </Grid>
            </Container>
        </div>
    );
}

export default LoadSession;
