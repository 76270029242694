import { Container, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withAuthentication } from '../login/withAuthentication';
import { fs_loadAllSessions, fs_loadSessions, fs_loadUsers } from '../../app/firestore';
import dayjs from 'dayjs';
import { mkConfig, generateCsv, download } from "export-to-csv";

function Statistics(props)
{
    const [users, setUsers] = useState([]);
    const csvConfig = mkConfig({ useKeysAsHeaders: true });

    useEffect(() =>
    {
        getData();
        // cleanup function
        return () =>
        {
        };
    }, []);

    const getData = (appendToEnd = false) =>
    {
        console.log("Loading data...");
        fs_loadUsers(drawOnboarding);
    }

    const drawOnboarding = (dataRaw, appendToEnd = false) =>
    {
        console.log("Received data onboarding");
        let results = { goals: {}, why: {}, challenges: {} };
        for (var i = 0; i < dataRaw.length; i++)
        {
            let user = dataRaw[i];
            //exclude users marked in DB to be excluded from statistics (eg. test users)
            if (user.stats_exclude === true)
                console.log(user);
            else
            {
                if (user.survey !== undefined)
                {
                    countSurveyValues(user.survey.goals, results.goals);
                    countSurveyValues(user.survey.why, results.why);
                    countSurveyValues(user.survey.challenges, results.challenges);
                }
            }
        }

        console.log(results);

        /* SAVE AS CSV
        const csv = generateCsv(csvConfig)([results.goals]);
        download(csvConfig)(csv);

        const csv2 = generateCsv(csvConfig)([results.why]);
        download(csvConfig)(csv2);

        const csv3 = generateCsv(csvConfig)([results.challenges]);
        download(csvConfig)(csv3);
        */
        

        //setUsers(users);
        //fs_loadAllSessions(onAllSessionsLoaded);
    };

    const countSurveyValues = (input, output) =>
    {
        if (input !== undefined)
        {
            for (var j = 0; j < input.length; j++)
            {
                if (output[input[j]] === undefined)
                    output[input[j]] = 0;

                output[input[j]] += 1;
            }
        }
    };

    const drawUsersAndSessions = (dataRaw, appendToEnd = false) =>
    {
        console.log("Received data.");
        let users = [];
        for (var i = 0; i < dataRaw.length; i++)
        {
            let user = dataRaw[i];
            //exclude users marked in DB to be excluded from statistics (eg. test users)
            if (user.stats_exclude === true)
                console.log(user);
            else
            {
                let dataRow =
                {
                    uid: user.uid,
                    created: user.created,
                    onboarding: user.onboarding,
                    started_sessions: user.started_sessions,
                    real_started_sessions: 0,
                    completed_sessions: user.completed_sessions,
                    real_completed_sessions: 0,
                }
                
                users.push(dataRow);
            }
        }

        setUsers(users);
        fs_loadAllSessions(onAllSessionsLoaded);
    };

    const onAllSessionsLoaded = (sessions) =>
    {
        let newUsers = [...users];
        let newSessions = [];
        
        for (var i = 0; i < newUsers.length; i++)
        {
            for (var j = 0; j < sessions.length; j++)
            {
                
                if (sessions[j].userId === newUsers[i].uid)
                {
                    //if (newUsers[i].sessions === undefined)
                    //    newUsers[i].sessions = [];

                    let newSession = {
                        short_summary: sessions[j].short_summary,
                        rating: sessions[j].rating,
                        status: sessions[j].status,
                        created: sessions[j].created
                    }

                    if (sessions[j].messages !== undefined && sessions[j].messages.length > 0)
                    {
                        let lastMessageTime = dayjs(sessions[j].messages[sessions[j].messages.length - 1].created);
                        let sessionTime = dayjs(sessions[j].created);
                        newSession.length = lastMessageTime.diff(sessionTime, 'second');
                        newSession.messages_length = sessions[j].messages.length;
                    }

                    //newUsers[i].sessions.push(newSession);
                    newSessions.push(newSession);

                    newUsers[i].real_started_sessions++;
                    if (newSession.status === 'completed')
                        newUsers[i].real_completed_sessions++;

                }
            }
        }

        console.log(newUsers);
        console.log(newSessions);

        const csv = generateCsv(csvConfig)(newSessions);
        download(csvConfig)(csv);

        const csv2 = generateCsv(csvConfig)(newUsers);
        download(csvConfig)(csv2);

    }

    return (
        <Container disableGutters maxWidth="sm">

        </Container>
    );
}

export default withAuthentication(Statistics);
