import TokenRoundedIcon from '@mui/icons-material/TokenRounded';
import { Backdrop, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { default as React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { am_logEvent } from '../../app/amplitude';
import { EXTRA_SESSION_COST, ROLES } from '../../app/config';
import { fs_loadSessions, fs_updateUser, fs_updateUserProperty, ga_logEvent } from '../../app/firestore';
import { pp_getSubscriptionStatus } from '../../app/paypal';
import Spacer from '../common/Spacer';
import LoadingScreen from '../dialog/LoadingScreen';
import { withAuthentication } from '../login/withAuthentication';
import TopUpTokens from '../topup/TopUpTokens';
import { getSessions, getUser, setLastSession, setSession, setSessions, setUserProperties, setUserProperty } from '../user/UserSlice';
import Header from './Header';
import SessionsTimeline from './SessionsTimeline';
import { showError } from '../../app/showError';

function Olivya(props)
{
    dayjs.extend(relativeTime);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const sessions = useSelector(getSessions);

    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [isBrowser, set_isBrowser] = React.useState(true);
    const [openTopUpDialog, setOpenTopUpDialog] = React.useState(false);
    const [numberOfSessionsToLoad, setNumberOfSessionsToLoad] = React.useState(30);
    const [allSessionsLoaded, setAllSessionsLoaded] = React.useState(false);
    
    useEffect(() =>
    {
        window.scrollTo(0, 0);
        loadSessions(numberOfSessionsToLoad);
        checkSubscription();
        //TODO: Check if subscription is active, and it needs updating the status
        updateUserLastOnlineTime();
        updateAppStandaloneOrWeb();
            
        // cleanup function
        return () => {};
    }, []);

    const updateUserLastOnlineTime = () =>
    {
        fs_updateUserProperty('last_online', dayjs().format(), () => {});
    }

    const updateAppStandaloneOrWeb = () =>
    {
        let displayMode = 'browser';
        const mqStandAlone = '(display-mode: standalone)';
        if (navigator.standalone || window.matchMedia(mqStandAlone).matches)
            displayMode = 'standalone';

        am_logEvent('app_mode', { mode: displayMode });
        if (displayMode === 'standalone')
            set_isBrowser(false);

    }

    // Check if the user is still subscribed via PayPal
    const checkSubscription = () =>
    {
        // ONly check if the user is subscribed and their check time is after now
        if (user.role === ROLES.SUBSCRIBER && dayjs().isAfter(dayjs(user.subscriptionCheckTime)))
        {
            pp_getSubscriptionStatus(user.subscriptionId, subscription =>
            {
                const newUser = {
                    role: ROLES.SUBSCRIBER,
                };

                if (subscription.name === 'RESOURCE_NOT_FOUND')
                {
                    newUser.role = ROLES.GUEST;
                    fs_updateUser(newUser, () => dispatch(setUserProperties(newUser)));
                }

                if (subscription.status === 'ACTIVE')
                {
                    newUser.subscriptionCheckTime = dayjs(subscription.billing_info.next_billing_time).format();
                    fs_updateUser(newUser, () => dispatch(setUserProperties(newUser)));
                }

                // If status is any of the inactive ones, set the user back to "guest" role
                if (subscription.status === 'SUSPENDED' ||
                    subscription.status === 'CANCELLED' ||
                    subscription.status === 'EXPIRED')
                {
                    newUser.role = ROLES.GUEST;
                    fs_updateUser(newUser, () => dispatch(setUserProperties(newUser)));
                }

            });
        }
    }

    const loadSessions = (numberOfSessions) =>
    {
        setOpenBackdrop(false);
        fs_loadSessions(numberOfSessions, onLoadSessions);
    }

    const onLoadSessions = newSessions =>
    {
        //if (newSessions.length === sessions.length)
        //    setAllSessionsLoaded(true);

        // Save last session for history context when starting a new session
        if (newSessions.length > 0)
            dispatch(setLastSession(newSessions[0]));

        dispatch(setSessions(newSessions));
        setOpenBackdrop(false);
    }

    const loadSession = session =>
    {
        dispatch(setSession(session));

        if (session.status === 'completed')
            navigate("/session_completed", { replace: true });

        if (session.status === 'started')
            navigate("/session", { replace: true });
    }

    const [openPurchaseDialog, setOpenPurchaseDialog] = React.useState(false);

    const handlePurchase = () =>
    {
        setOpenBackdrop(true);
        setOpenPurchaseDialog(false);
        if (user.tokens >= EXTRA_SESSION_COST)
        {
            fs_updateUserProperty('tokens', user.tokens - EXTRA_SESSION_COST, () =>
            {
                am_logEvent('spend_virtual_currency', { value: EXTRA_SESSION_COST, virtual_currency_name: 'Tokens', item_name: 'Extra Session' });

                dispatch(setUserProperty({ property: 'tokens', value: user.tokens - EXTRA_SESSION_COST }));
                loadSession({ id: 0, status: 'started' });
            });
        }
        else
        {
            setOpenTopUpDialog(true);
        }
    };

    const handleLoadOlderSessions = () =>
    {
        loadSessions(numberOfSessionsToLoad + 10);
        setNumberOfSessionsToLoad(numberOfSessionsToLoad + 10);
    }

    const onActionsChange = () =>
    {
        loadSessions(numberOfSessionsToLoad);
    }

    return (
        <Container disableGutters maxWidth="sm">
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop} >
                <LoadingScreen />
            </Backdrop>
            <Header
                sessions={sessions}
                onTimerComplete={() => loadSessions(numberOfSessionsToLoad)}
                onTokensClick={() => setOpenTopUpDialog(true)}
                isBrowser={isBrowser}
            />
            <SessionsTimeline
                sessions={sessions}
                onLoadSession={loadSession}
                onPurchaseSession={() => setOpenPurchaseDialog(true)}
                onLoadOlderSessions={handleLoadOlderSessions}
                allSessionsLoaded={allSessionsLoaded}
                onActionsChange={onActionsChange}
            />
            <Spacer height={120} />
            <Dialog
                open={openPurchaseDialog}
                onClose={() => setOpenPurchaseDialog(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography >Use Tokens to start new session?</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant='timeline_title'>Unlocking an extra session costs</Typography>
                        <br />
                        <Typography variant='timeline_title'>{EXTRA_SESSION_COST} Tokens.</Typography>
                        <br /><br />
                        <Typography variant='timeline_title'>You have {user.tokens} Tokens.</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack>
                        <Button endIcon={<TokenRoundedIcon />} variant='contained' onClick={handlePurchase} >
                            SPEND {EXTRA_SESSION_COST}
                        </Button>
                        <Button onClick={() => setOpenPurchaseDialog(false)}>Cancel</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openTopUpDialog}
                onClose={() => setOpenTopUpDialog(false)}
            >
                <DialogTitle id="top-up-dialog">
                    <Typography >Top Up Your Tokens</Typography>
                </DialogTitle>
                <DialogContent>
                    <TopUpTokens
                        onSuccess={() =>
                        {
                            setOpenPurchaseDialog(true);
                            setOpenTopUpDialog(false);
                        }}
                        onFail={() =>
                        {
                            setOpenTopUpDialog(false);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                        <Button onClick={() => setOpenTopUpDialog(false)}>CLOSE</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default withAuthentication(Olivya);
